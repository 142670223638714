import React from "react";
import '../Style/software-skills.css';
import {BsPatchCheckFill} from 'react-icons/bs'
import C from '../assets/C_Logo.png' 
import C_sharp from '../assets/c-sharp.png' 
import JS from '../assets/Javascript.png' 
import Python from '../assets/Python-logo.png' 
import Kotlin from '../assets/Kotlin_logo.png' 
import HTML from '../assets/html-5.png' 
import CSS from '../assets/css-3.png' 
import Github from '../assets/github.png' 
import Swift from '../assets/Swift_logo.png' 

function SoftwareSkills(props){
    return (
        <section id='skills'>
            <h5>What Skills I have</h5>   
            <h2>My Experience</h2>

            <div className="container experience__container">
                <div className="experience__frontend">
                    <h3>Frontend Development</h3>
                    <div className="experience__content">
                        <div className="icon__experince">
                            <img src={C}  />
                        </div>
                        <div className="icon__experince">
                            <img src={C_sharp}  />
                        </div>
                        <div className="icon__experince">
                            <img src={Python}  />
                        </div>
                        <div className="icon__experince">
                            <img src={JS}  />
                        </div>
                        <div className="icon__experince">
                            <img src={HTML}  />
                        </div>
                        <div className="icon__experince">
                            <img src={CSS}  />
                        </div>
                        <div className="icon__experince">
                            <img src={Github}  />
                        </div>
                        
                        
                    </div>
                    <h3>Currently learning</h3>
                    <div className="experience__content currently__learning">
                    
                        <div className="icon__experince">
                            <img src={Kotlin}  />
                        </div>
                        <div className="icon__experince">
                            <img src={Swift}  />
                        </div>
                    </div>
                    
                </div>
            </div>

        </section>
    )
}


export default SoftwareSkills; 