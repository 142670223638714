import React from "react";
import {BsLinkedin} from 'react-icons/bs';
import {FaGithub} from 'react-icons/fa';


function HeaderSocials(){
    return(
        <div className="header__socials">
            <a href="https://www.linkedin.com/in/jos%C3%A9-fernando-crist%C3%B3bal-huerta-71820a209/" target="_blank"><BsLinkedin /></a>
            <a href="https://github.com/fercuacho?tab=repositories" target="_blank"><FaGithub /></a>  

        </div>
    )
}

export default HeaderSocials;