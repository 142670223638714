import './App.css';
import SoftwareSkills from './Components/software-skills';
import Header from './Components/header';
import Nav from './Components/nav';
import Projects from './Components/projects';
import Contact from './Components/contact';
import About from './Components/about';
import Footer from './Components/footer';


function App() {
  return (
    <div className="App">
      <Header/>
      <Nav/>
      <About/>
      <Projects/>
      <SoftwareSkills />
      <Contact/>
      <Footer/>
    
    </div>
  );
}

export default App;
