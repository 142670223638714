import React from 'react';
import '../Style/header.css';
import CTA from './CTA';
import ME from '../assets/me.png' 
import HeaderSocials from './headerSocials';

function Header(){
    return(
        <header>
            <div className='container header__container'>
                <h5>Hello I'm</h5>
                <h1>Fernando Cristóbal </h1>
                <h5 className='text-light'> Frontend Developer  </h5>
                <CTA />
                <HeaderSocials />

                <div className='me'>
                    <img className='foto-me' src={ME} alt="me" />
                </div>
                <a href="#contact" className='scroll__down'>Scroll Down</a>            
            </div>
        </header>
    )
}

export default Header;